<template>
  <div class="rightLink">
    <div class="bottomDiv">
      <div class="title">充电站管理</div>
      <div class="formDiv" style="width:1200px">
        <el-form label-position="right" label-width="95px">
          <el-row :gutter="20" style="margin-bottom: 18px">
            <el-col :span="6">
              <el-form-item label="选择省份:">
                <el-select
                  v-model="FormData.province"
                  placeholder="请选择省"
                  @change="handleProvinceChange"
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="provinces in provinceList"
                    :key="provinces.value"
                    :value="provinces.label"
                    :label="provinces.label"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="选择市:">
                <el-select
                  v-model="FormData.city"
                  placeholder="请选择市"
                  @change="handleCityChange"
                  clearable
                >
                  <el-option
                    v-for="citys in cityList[0]"
                    :key="citys.value"
                    :value="citys.label"
                    >{{ citys.label }}</el-option
                  >
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="选择区/县:">
                <el-select v-model="FormData.county" placeholder="请选择区/县" clearable>
                  <el-option
                    v-for="countys in countyList[0]"
                    :key="countys.value"
                    :value="countys.label"
                    >{{ countys.label }}</el-option
                  >
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="站点类型:">
                <el-select
                  v-model="FormData.type_id"
                  placeholder="请选择站点类型"
                  clearable
                >
                  <el-option
                    v-for="car in carList"
                    :key="car.value"
                    :value="car.value"
                    :label="car.label"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="站点名称:">
                <el-input
                  v-model="FormData.station_name"
                  placeholder="请输入站点名称"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="充电站编号:">
                <el-input
                  v-model="FormData.station_number"
                  placeholder="请输入充电站编号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col v-show="isadmin" :span="6">
              <el-form-item label="运营商:">
                <el-select
                  v-model="FormData.username"
                  placeholder="请选择运营商"
                  clearable
                >
                  <el-option
                    v-for="aa in users"
                    :key="aa.username"
                    :value="aa.username"
                    :label="aa.username"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <div class="BtnSearch" @click="searchBtn()">查询</div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 表格部分 -->
      <div class="tableDiv">
        <el-table
          :data="tableData"
          border
          v-loading="loading"
          height="480px"
          style="width: 90%; margin: 20px auto; overflow-y: auto"
        >
          <el-table-column label="序号" type="index" align="center" width="60">
            <template #default="{ $index }">
              {{ (currentPage - 1) * pageSize + $index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="充电站编号" prop="station_number" width="100" />
          <el-table-column label="运营商" prop="username" />
          <el-table-column label="站点名称" prop="station_name" width="160" />
          <el-table-column label="站点车型" prop="type_id">
            <template #default="scope">
              <span v-if="scope.row.type_id == 2">电动车</span>
              <span v-if="scope.row.type_id == 4">电动汽车</span>
            </template>
          </el-table-column>
          <el-table-column label="省" prop="province" width="80" />
          <el-table-column label="市" prop="city" width="80" />
          <el-table-column label="县/区" prop="county" width="80" />
          <el-table-column label="社区" prop="community" width="190" />
          <el-table-column label="详细地址" prop="station_addr" width="150" />
          <el-table-column label="经度" prop="cord_j" width="130" />
          <el-table-column label="纬度" prop="cord_w"  width="130"/>
          <!-- <el-table-column label="枪头总数" prop="plug" /> -->
          <el-table-column label="计费模板" prop="price_name" />
          <el-table-column
            label="操作"
            width="230px"
            fixed="right"
            align="center"
          >
            <template #default="scope">
              <div class="btntext">
                <el-link
                  target="_blank"
                  @click="handleEdit(scope.$index, scope.row)"
                  >编辑
                </el-link>
                <el-link
                  type="warning"
                  @click="handleDel(scope.$index, scope.row)"
                  >删除
                </el-link>
                <el-link
                  type="primary"
                  @click="handleAdd(scope.$index, scope.row)"
                   v-if="!isadmin"
                  >设备绑定
                </el-link>
                <el-link
                  type="success"
                  @click="handleCost(scope.$index, scope.row)"
                   v-if="!isadmin"
                  >配置计费
                </el-link>
              </div>

              <!-- <div style="display:flex;">
                <div
                  class="BtnSearch1 BtnSearch"
                  @click="handleEdit(scope.$index, scope.row)"
                  >编辑</div
                >
                <div
                  class="BtnSearch1 BtnSearch"
                  @click="handleDel(scope.$index, scope.row)"
                  >删除</div
                >
              </div>
              <div style="display:flex;">
                <div class="BtnSearch1 BtnSearch" 
                @click="handleAdd(scope.$index, scope.row)"
                >
                  设备绑定
                </div>
               
                <div v-if="scope.row.type_id == 4 "
                  class="BtnSearch1 BtnSearch"
                  
                  @click="handleCost(scope.$index, scope.row)"
                  >配置计费</div
                >
              </div> -->
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            :current-page="FormData.page"
            :page-size="pageSize"
            :small="small"
            layout="total, slot, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
            <template #default>
              <span class="el-pagination__total">{{ Math.ceil(total / pageSize) }} 页 </span>
            </template>
          </el-pagination>
        </div>
        <div v-dialogdrag>
          <el-dialog
            v-model="Dialog.editLevy"
            title="编辑"
            width="620px"
            :before-close="closeDialog"
            class="dialog"
          >
            <div class="formDiv1">
              <el-form
                ref="FormRules"
                :rules="rules"
                :model="FormData"
                label-position="right"
                label-width="100px"
              >
                <el-row :gutter="20">
                  <el-col :span="1"></el-col>
                  <el-col :span="10">
                    <el-form-item label="站点名称:">
                      <el-input
                        v-model="Dialog.DialogData.station_name"
                        placeholder="请输入站点名称"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <!-- <el-col :span="1"></el-col> -->
                  <el-col :span="10">
                    <el-form-item label="选择省份:">
                      <el-select
                        style="width: 100%"
                        v-model="Dialog.DialogData.province"
                        placeholder="请选择省"
                        @change="handleProvinceChange"
                      >
                        <el-option
                          v-for="provinces in provinceList"
                          :key="provinces.value"
                          :value="provinces.label"
                          :label="provinces.label"
                        />
                        <!-- </el-option> -->
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="1"></el-col>
                  <!-- <el-col :span="3"></el-col> -->
                  <el-col :span="10">
                    <el-form-item label="选择市:">
                      <el-select
                        style="width: 100%"
                        v-model="Dialog.DialogData.city"
                        placeholder="请选择市"
                        @change="handleCityChange"
                      >
                        <!-- <el-option value="">请选择</el-option> -->
                        <el-option
                          v-for="citys in cityList[0]"
                          :key="citys.value"
                          :value="citys.label"
                          >{{ citys.label }}</el-option
                        >
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10">
                    <el-form-item style="width: 100%" label="选择区/县:">
                      <el-select
                        v-model="Dialog.DialogData.county"
                        placeholder="请选择区/县"
                      >
                        <!-- <el-option value="">请选择</el-option> -->
                        <el-option
                          v-for="countys in countyList[0]"
                          :key="countys.value"
                          :value="countys.label"
                          >{{ countys.label }}</el-option
                        >
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="1"></el-col>
                  <el-col :span="10">
                    <el-form-item label="经度:">
                      <el-input
                        v-model="Dialog.DialogData.cord_j"
                        placeholder="请输入位置经度(0-180°)"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10">
                    <el-form-item label="纬度:">
                      <el-input
                        v-model="Dialog.DialogData.cord_w"
                        placeholder="请输入位置纬度(0-90°)"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!--<el-row :gutter="20">
                  <el-col :span="1"></el-col>
                  <el-col :span="10">
                    <el-form-item label="充电枪数量:">
                      <el-input
                        v-model="Dialog.DialogData.plug"
                        placeholder="请输入充电枪数量"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10">
                    <el-form-item label="功率:">
                      <el-input
                        v-model="Dialog.DialogData.power"
                        placeholder="请输入功率"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>-->
                <el-row :gutter="20">
                  <el-col :span="1"></el-col>
                  <el-col :span="10" v-if="isadmin">
                    <el-form-item label="运营商:">
                      <el-select
                        v-model="Dialog.DialogData.username"
                        placeholder="请选择运营商"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="aa in users"
                          :key="aa.username"
                          :value="aa.username"
                          :label="aa.username"
                        />
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10">
                    <el-form-item label="车辆类型:">
                      <el-select
                        v-model="Dialog.DialogData.type_id"
                        placeholder="请选择车辆类型"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="aa in type_id"
                          :key="aa.value"
                          :value="aa.value"
                          :label="aa.label"
                        />
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button class="Btn" @click="closeDialog">取消</el-button>
                <el-button class="Btn" type="primary" @click="submitDialog"
                  >提交</el-button
                >
              </span>
            </template>
          </el-dialog>
        </div>
        <div v-dialogdrag>
          <el-dialog
            v-model="Dialog1.editLevy"
            title="设备绑定"
            width="60vw"
            :before-close="closeDialog"
            class="dialog"
          >
            <el-checkbox-group v-model="charg_list">
              <el-checkbox
                @change="chargs"
                v-for="item in typeid == 2
                  ? JSON.parse(JSON.stringify(chargList2))
                  : JSON.parse(JSON.stringify(chargList4))"
                :key="item"
                :label="item.device_id"
                >{{ item.device_id }}</el-checkbox
              >
            </el-checkbox-group>

            <template #footer>
              <span class="dialog-footer">
                <!-- <div>
                  <el-button class="Btn" @click="addDevice" style="float:left">添加</el-button>
                </div> -->
                <el-button class="Btn" @click="closeDialog">取消</el-button>
                <el-button class="Btn" type="primary" @click="submitDialog1"
                  >绑定</el-button
                >
              </span>
            </template>
          </el-dialog>
        </div>
        <div v-dialogdrag>
          <el-dialog
            v-model="Dialog2.editLevy"
            title="绑定计费模板"
            width="820px"
            :before-close="closeDialog"
            class="dialog"
          >
            <div class="formDiv1">
              <el-form
                ref="FormRules"
                :rules="rules"
                :model="FormData"
                label-position="right"
                label-width="100px"
              >
                <el-row :gutter="20">
                  <el-col :span="1"></el-col>
                  <el-col :span="9">
                    <el-form-item label="充电站id:" >
                      <el-input
                        disabled
                        v-model="Dialog2.DialogData.station_number"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="1"></el-col>
                  <el-col :span="9">
                    <el-form-item label="选择计费模板:">
                      <el-select
                        style="width: 100%"
                        v-model="Dialog2.DialogData.price_name"
                      >
                        <el-option
                          @click="changesmb(item)"
                          v-for="item in temps"
                          :key="item"
                          :value="item.id"
                          :label="item.price_name"
                        />
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="1"></el-col>
                </el-row>
              </el-form>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button class="Btn" @click="closeDialog">取消</el-button>
                <el-button class="Btn" type="primary" @click="submitDialog2"
                  >提交</el-button
                >
              </span>
            </template>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { onMounted } from "vue";
import { ref } from "vue";
import { onMounted, reactive, toRefs } from "vue-demi";
import {
  sel_station,
  upd_station,
  manage_device,
  sel_temp,
  billing_model,
  operator_data,
  sel_device,
  remove_station,
} from "@/request/api";
import { ElMessageBox, ElMessage } from "element-plus";
import { regionData } from "element-china-area-data";
export default {
  name: "StationManage",
  setup() {
    const data = reactive({
      //表单数据
      carList: [
        {
          value: 2,
          label: "电动车",
        },
        {
          value: 4,
          label: "电动汽车",
        },
      ],
      car: "",
      FormData: {
        operator_id: sessionStorage.getItem("userID"),
        page: 1,
      },
      tableData: [],
      tableData1: [],
      device_list: [],
      charg_list: [],
      chargLists: [],
      chargList2: [],
      chargList4: [],
      //   弹框
      Dialog: {
        editLevy: false,
        DialogData: {},
      },
      Dialog1: {
        editLevy: false,
        DialogData: {},
      },
      Dialog2: {
        editLevy: false,
        DialogData: {},
      },
      price_id: "",
      loading: false,

      //   分页
      currentPage: 1,
      pageSize: 20,
      total: 0,
      checked: 0,
      small: true,

      options: regionData,
      selectedOptions: "",
      // 省市县
      regionData,
      provinceList: [],
      cityList: [],
      countyList: [],
      province: "",
      city: "",
      county: "",

      temps: [],
      isadmin: false,
      users: [],
      type_id: [
        {
          label: "电动车",
          value: 2,
        },
        {
          label: "电动汽车",
          value: 4,
        },
      ],
      typeid: "",

    });
    const searchBtn = () => {
      data.FormData.page = 1;
      getList();
    };

    const delBtn = (index) => {
      // console.log(index,'index');
      // console.log(data.device_list.length,'row');
      if (data.device_list.length <= 1) {
        //如果只有一个框则不可以删除
        return false;
      }
      data.device_list.forEach((it, ites) => {
        if (it == index) {
          data.device_list.splice(ites, 1); //删除数组中对应的数据也就是将这个位置的框删除
        }
      });
      // data.device_list.splice(index, 1) //删除数组中对应的数据也就是将这个位置的框删除
    };
    const handleEdit = (index, row) => {
      data.Dialog.editLevy = true;
      data.Dialog.DialogData = JSON.parse(JSON.stringify(row));
    };

    const handleAdd = (index, row) => {
      data.Dialog1.editLevy = true;
      data.Dialog1.DialogData = row;
      data.typeid = row.type_id;
    };

    const handleCost = (index, row) => {
      console.log(row, "0000");
         const aaa = {
          type_id: row.type_id,
          username: localStorage.getItem("username"),
         };
      sel_temp(aaa).then((res) => {
        if (res) {
          console.log(res, "模板列表");
          data.loading = false;
          if (res.code == 200) {
            data.temps = res.data;
            console.log(data.temps, "pppppp");
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
      data.Dialog2.editLevy = true;
      data.Dialog2.DialogData = row;
      data.price_id = row.price_id;
    };
    const addDevice = () => {
      if (data.device_list.length <= 20) {
        data.device_list.push("");
      }
    };
    const closeDialog = () => {
      data.Dialog.editLevy = false;
      data.Dialog1.editLevy = false;
      data.Dialog2.editLevy = false;
    };
    const submitDialog = () => {
      submitData();
    };

    const submitDialog1 = () => {
      const dataa = {
        station_name: data.Dialog1.DialogData.station_name,
        id: data.Dialog1.DialogData.id,
        device_list: data.charg_list,
      };
      console.log(dataa, "tyyyyyyyyyy");
      manage_device(dataa).then((res) => {
        console.log(res, "manage_device");
        if (res) {
          closeDialog();
          if (res.code == 200) {
            ElMessage({
              showClose: true,
              dangerouslyUseHTMLString: true,
              message: res.msg,
              type: "绑定成功！",
            });

            getList();
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("绑定失败");
        }
      });
    };
    const changesmb = (dd) => {
      console.log(dd);
      data.price_id = dd.id;
    };
    const submitDialog2 = () => {
      const dataa = {
        id: data.Dialog2.DialogData.id,
        price_id: data.price_id,
      };
      billing_model(dataa).then((res) => {
        console.log(res, "下发模板");
        if (res) {
          closeDialog();
          if (res.code == 200) {
            ElMessage({
              showClose: true,
              dangerouslyUseHTMLString: true,
              message: res.msg,
              type: "success",
            });

            getList();
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("绑定失败");
        }
      });
    };

    const submitData = () => {
      const dataa = data.Dialog.DialogData;

      upd_station(dataa).then((res) => {
        console.log(res);
        if (res) {
          closeDialog();
          if (res.code == 200) {
            ElMessage({
              showClose: true,
              message: "编辑成功",
              type: "success",
            });
            getList();
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("编辑失败");
        }
      });
    };
    const handleDel = (index, row) => {
      const dataa = {
        id: row.id,
      };
      ElMessageBox.confirm("您确认要删除充电站吗?", "警告", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          remove_station(dataa).then((res) => {
            if (res) {
              data.loading = false;
              if (res.code == 200) {
                ElMessage.success("删除成功");
                getList();
              } else {
                ElMessage.error(res.msg);
              }
            } else {
              ElMessage.error("删除失败");
            }
          });
        })
        .catch(() => {});
    };
    const getList = () => {
      const dataa = data.FormData;
      data.loading = true;
      sel_station(dataa).then((res) => {
        console.log(res, "充电站列表");
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.tableData = res.data;
            data.total = res.data.length;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };
    const chargs = (e) => {
      console.log(e, "vallllll");
      console.log(data.charg_list, "charg_listcharg_list");
      // console.log(row,'aaaaaaaaa');
    };

    const getchargList = () => {
      data.loading = true;
      const dataa = {
        username: localStorage.getItem("username"),
      };
      sel_device(dataa).then((res) => {
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            console.log('res.data', res.data)
            res.data.forEach((a) => {
              // console.log(a,'aaaaaa');
              if (a.station_name == null) {
                if (a.type_id == 2) {
                  data.chargList2.push(a);
                  console.log(
                    JSON.parse(JSON.stringify(data.chargList2)),
                    "this.chargListthis.chargList222"
                  );
                } else if (a.type_id == 3 || a.type_id == 4) { // 直流桩或交流桩
                  data.chargList4.push(a);
                  console.log(
                    JSON.parse(JSON.stringify(data.chargList4)),
                    "this.chargListthis.chargList4444"
                  );
                }
              }
            });
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
      console.log(data.options, "options");
    };
    const getdevList = () => {
      data.loading = true;
      manage_device().then((res) => {
        console.log(res, "manage_device");
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.tableData1 = res.data;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
      console.log(data.options, "options");
    };

    const handleChange = (value) => {
      console.log(value, "value");
      // console.log(myOptions,'myOptions')
      const checkedNodes = this.$refs.myOptions.getCheckedNodes()[0].pathLabels;
      console.log(checkedNodes);
    };

    const handleChange1 = () => {
      // console.log(e,'value')
      const checkedNodes = this.$refs.myOptions1.getCheckedNodes();
      console.log(checkedNodes);
    };
    const handleSizeChange = (val) => {
      data.pageSize = val;
    };
    const handleCurrentChange = (val) => {
      data.FormData.page = val;
      getList();
      //
    };

    const initProvinceList = () => {
      // 初始化省份列表数据源
      regionData.forEach((tab) => {
        data.provinceList.push(tab);
      });
    };
    const handleProvinceChange = (val) => {
      console.log(val, "009-");
      data.cityList = [];
      data.countyList = [];
      data.province = val;
      data.FormData.city = "";
      data.FormData.county = "";
      console.log(data.province, "]]");
      initCityList();
    };

    const initCityList = () => {
      console.log(data.province, "--2");
      // 初始化城市列表数据源
      data.provinceList.forEach((tab1) => {
        // console.log(tab1,'--==tab1');
        if (tab1.label == data.province) {
          data.cityList.push(tab1.children);
        }
      });
    };

    const handleCityChange = (value1) => {
      data.countyList = [];
      data.city = value1;
      data.FormData.county = "";
      console.log(data.city, "]]999");
      initCountyList();
    };

    const initCountyList = () => {
      // 初始化县/区列表数据源
      console.log(data.city, "--299");
      // 初始化城市列表数据源
      data.cityList[0].forEach((tab2) => {
        console.log(tab2, "tab2");
        // tab2.forEach((tab3)=>{
        //   console.log(tab3,'tab3');
        //    if(tab3.label==this.selectedCity){
        //  this.countyList.push(tab3.children)
        // }
        // })
        if (tab2.label == data.city) {
          data.countyList.push(tab2.children);
        }
      });
    };

    const getUsername = () => {
      const username1 = localStorage.getItem("username");
      const users1 = [];
      operator_data().then((res) => {
        console.log(res, "sssssss");
        data.users = res;
        res.forEach((a) => {
          users1.push(a.username);
        });
        if (users1.indexOf(username1) == -1) {
          data.isadmin = true;
        } else {
          data.isadmin = false;
          data.FormData.username = username1;
        }
      });
    };

    onMounted(() => {
      console.log(data);
      getList();
      initProvinceList();
      getUsername(), getchargList();
    });
    return {
      ...toRefs(data),
      ref,
      searchBtn,
      delBtn,
      getUsername,
      handleEdit,
      handleDel,
      closeDialog,
      submitDialog,
      submitDialog1,
      changesmb,
      submitDialog2,
      submitData,
      getList,
      getdevList,
      handleAdd,
      handleCost,
      addDevice,
      handleChange,
      handleChange1,
      handleSizeChange,
      handleCurrentChange,
      initProvinceList,
      handleProvinceChange,
      handleCityChange,
      initCityList,
      initCountyList,
      getchargList,
      chargs,
    };
  },
};
</script>
<style scoped>
.formDiv {
  margin-top: 10px;
  height: 120px;
}
.formDiv1 {
  margin-top: 10px;
  height: 210px;
}
.el-row {
  height: 45px;
}
.el-select /deep/ .el-input__wrapper {
  width: 500px !important;
  --el-input-focus-border-color: #7bd8d3;
}
.btn {
  color: #fff;
  background-color: #03beb0;
  border-color: #03beb0;
  width: 55px;
  height: 28px;
  line-height: 28px;
  margin-left: -100px;
}
.Btns {
  color: #fff;
  background-color: #03beb0;
  border-color: #03beb0;
  width: 175px;
  margin-left: 40px;
  margin-top: 20px;
}
.btn1 {
  margin-left: 340px;
}
.btn:hover {
  background-color: #05d5c7;
  border-color: #05d5c7;
}

.btn:focus {
  background-color: #03beb0;
  border-color: #03beb0;
}

.BtnSearch1 {
  width: 100px !important;
  margin: 4px 4px;
}

.pagination {
  display: flex;
  justify-content: space-between;
  padding: 0 67px;
  margin-top: -10px;
}

.bottomDiv {
  height: 100%;
}
.statistics {
  display: flex;
  width: 90%;
  padding-top: 15px;
  /* background: red; */
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
}
.statistics1 {
  display: flex;
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
}
.statistics1 > div > a {
  text-decoration: none;
  color: black;
}
.s_title {
  font-size: 14px;
}
.s_title .el-icon {
  float: right;
  color: red;
}
.p_bottom .el-icon {
  float: right;
  color: green;
}
.s_water {
  line-height: 40px;
  font-size: 19px;
  /* font-weight: bold; */
}
.s_profress {
  line-height: 25px;
}
.statistics > div {
  width: 16%;
  margin: 10px auto;
  background: #f0f2f5;
  /* border: 1px solid red; */
  padding: 10px 20px;
  border-radius: 5px;
  /* height: 200px; */
}
.statistics > div > .el-progress {
  width: 45%;
}
.el-progress /deep/ .el-progress-bar__outer {
  --el-border-color-lighter: #fff;
}
.statistics1 > div {
  width: 13%;
  margin: 10px auto;
  /* border: 1px solid red; */
  padding: 10px;
  /* height: 200px; */
}
.input-with-select {
  width: 10vw;
}
.el-link {
  margin: 0 3px;
}
</style>
